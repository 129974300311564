import consola from 'consola'
import { NextoryContext } from '~/nx-types'

const logger = consola.withScope('Helpers - fetchCampaignByCode')

export default async function fetchCampaignByCode(
  { $hummingbird, localePath, redirect }: NextoryContext,
  campaignCode: string,
  campaignChannel: string | null = null
) {
  if (!campaignCode) return undefined

  return (
    await $hummingbird.campaign
      .validateCode(campaignCode, campaignChannel)
      .catch(e => {
        // In case the campaign code needed a channel, the API will tell us.
        // Then, we can either redirect to a partner registration page or a generic campaign page (handled outside of this helper)
        const errorCode = e.response?.data?.error?.code
        const recommendedChannel = e.response?.data?.data?.channel
        if (errorCode === 2506 && recommendedChannel) {
          const isPartnerChannel = $hummingbird.marketProperties.partners.find(
            p => p.channel === recommendedChannel
          )
          if (isPartnerChannel) {
            // If the channel is one of the partner's (SJ, COOP, etc.), redirect to the partner registration page
            redirect(
              localePath({
                name: 'register-partner-channel',
                params: { channel: recommendedChannel },
              })
            )
          } else {
            // Else, we'll need special handling outside of this helper because the channel must be handled differently
            return {
              data: {
                channelRedirect: recommendedChannel,
              },
            }
          }
        }
        logger.error('Campaign code validation failed', e)
        return {
          data: undefined, // fallback
        }
      })
  ).data
}
