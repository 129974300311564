import { NextoryContext } from '~/nx-types'

/**
 * Turns a period unit and a number into a localized period unit.
 * Examples: 1+DAY = day, 10+MONTH = months, etc.
 */
export function getLocalizedPeriodUnit(
  periodUnit: string,
  number: number,
  { i18n }: NextoryContext
): string {
  const keys = {
    // keep the full key name here, for easier search
    DAY: 'global.period_unit.day',
    WEEK: 'global.period_unit.week',
    MONTH: 'global.period_unit.month',
    YEAR: 'global.period_unit.year',
  } as Record<string, string>

  return i18n.tc(keys[periodUnit] || keys.DAY, number)
}
