
import { mapMutations } from 'vuex'
import fetchCampaignByCode from '~/helpers/fetchCampaignByCode'
import { getLocalizedPeriodUnit } from '~/helpers/periodUnit'

export default {
  middleware({ $auth, localePath, query, redirect }) {
    if ($auth.user?.is_premium) {
      return redirect(localePath({ name: 'profile-profile' }))
    } else if ($auth.loggedIn && query.uuid) {
      return redirect(
        localePath({ name: 'register-subscription-picker', query })
      )
    } else if ($auth.loggedIn) {
      return redirect(localePath({ name: 'register-planinfo', query }))
    }
  },
  async asyncData(ctx) {
    const { query } = ctx
    let campaign = undefined

    if (query.code) {
      campaign = await fetchCampaignByCode(ctx, query.code, query.channel)
    }

    return {
      campaign,
    }
  },

  computed: {
    newFreeTrialLimitedBodyCopy() {
      // Different wording if the market has a time-limited free trial
      // @see /register/planform > newFreeTrialLimitedBodyCopy since it uses the same logic, but different keys
      const useLimitedFreeTrialCopy =
        // Note: We don't use !this.campaign?.total_allowed_secs, because it can be 0 (which means unlimited)
        this.campaign?.total_allowed_secs !== undefined ||
        this.$store.state.webConfig.config.trialLimitedHours
      const isCampaignUnlimited = this.campaign?.total_allowed_secs === 0

      const period =
        this.campaign?.campaign?.period ||
        this.$store.state.webConfig.config.trialDays
      const periodUnit = getLocalizedPeriodUnit(
        this.campaign?.campaign?.period_unit || 'DAY',
        period,
        this.$nuxt.context
      )

      // If unlimited, we don't need to show the limited free trial copy
      if (useLimitedFreeTrialCopy && !isCampaignUnlimited) {
        return this.$t('regfunnel.limited_free_trial.body', {
          // keys below are only for limited free trial hours consumption
          period,
          periodUnit,
          hours: Math.floor(
            this.campaign?.total_allowed_secs
              ? this.campaign?.total_allowed_secs / 3600
              : this.$store.state.webConfig.config.trialLimitedHours
          ),
        })
      }

      return this.$t('regfunnel.pre_create_account.body')
    },

    registrationUrlWithCallBack() {
      return this.localePath({
        name: 'profile-registration',
        query: {
          cb: this.callbackUrl,
          email: this.$route.query.email,
        },
      })
    },

    connectionUrlWithCallBack() {
      return this.localePath({
        name: 'profile-login',
        query: { cb: this.callbackUrl },
      })
    },

    callbackUrl() {
      let callbackPage = 'register-planinfo'

      if (this.$route.query.uuid) {
        // Subscription is already chosen, let's skip the planinfo page
        callbackPage = 'register-subscription-picker'
      }

      const query = {
        ...this.$route.query,
      }

      // Remove the email from the query (it's passed separately)
      delete query.email

      return this.$link.absolute(
        this.localePath({
          name: callbackPage,
          query,
        })
      )
    },
  },

  mounted() {
    this.SET_STEP(1)

    // TODO: Remove when new tracking is implemented (ACQ-94)
    this.$gtm?.push({
      event: 'Enhanced Ecommerce Checkout',
      ecommerce: {
        checkout: {
          actionField: {
            step: 1,
          },
          products: [{ id: '(not set)' }, { name: '(not set)' }],
        },
      },
    })

    try {
      this.$tracker.beforeRegistration({
        campaignCode: this.$route.query.code,
      })
    } catch (e) {
      this.$sentry.captureException(e)
    }
  },

  methods: {
    ...mapMutations(['SET_STEP']),
  },
}
